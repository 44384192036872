import React, { Suspense } from "react";
import Header from "../header/Header";
import ScrollToTopButton from "../../components/ScrollToUpButton";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { Outlet, useLocation } from "react-router-dom";

// Lazy load the Footer component
const LazyFooter = React.lazy(() => import("../footer/Footer"));

const MainLayout = () => {
  const { pathname } = useLocation();
  const isVenue = pathname.startsWith("/venue/");

  return (
    <>
      <ScrollToTopButton />
      {!isVenue && <Header />}

      <div className={isVenue ? "" : "flex justify-center w-full border"}>
        <div className={isVenue ? "" : "max-w-screen-main"}>
          <Outlet />
        </div>
      </div>
      {!isVenue && (
        <Suspense fallback={<div>Loading footer...</div>}>
          <LazyFooter />
        </Suspense>
      )}
      <NotificationContainer />
    </>
  );
};

export default MainLayout;
